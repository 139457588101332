import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
//import { Link } from "gatsby"
//import PropType from 'prop-types'

const Project = ({ description, title, github, stack, url, image, index }) => {
  return (
    <article className="project">
      <GatsbyImage image={image.gatsbyImageData} className="project-img" />
      <div className="project-info">
        <span className="project-number">0{index+1}</span>
        <h3>{title}</h3>
        <p className="project-desc">{description.description}</p>
        <div className="project-stack">
          {stack.tags.map((item, index) => {
            return (
              <span key={item.index}>{item}</span>
            )
          })}
        </div>
        <div className="project-links">
          <a href={github}>
            <FaGithubSquare className="project-icon"/>
          </a>
          <a href={url}>
            <FaShareSquare className="project-icon" />
          </a>
        </div>
      </div>
     </article>
  )
}

//Project.propType = {}
// image.childImageSharp.gatsbyImageData

export default Project
