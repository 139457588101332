import React from "react"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

const ProjectsPage = ({ 
  data: { allContentfulProject: {nodes: projects} }
}) => {
  
  return (
    <Layout>
      <Seo title="Projects" description="Projects Page" />
      <section className="projects-page">
        <Projects projects={projects} title="all projects" />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulProject {
      nodes {
        title
        url
        id
        github
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
        }
        stack {
          tags
        }
        description {
          description
        }
      }
    }
  }
`

export default ProjectsPage
